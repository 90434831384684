<template>
  <v-layout row wrap>
    <v-flex xs12 max-width-1400 full-width ma-auto px-2>
      <v-container grid-list-xl fluid ma-0 pa-0>
        <v-layout row wrap>
          <v-flex xs12>
            <h3
              class="primary--text graphik-medium my-3 text-xs-center"
              :class="{'font-25': $vuetify.breakpoint.smAndUp, 'font-20': $vuetify.breakpoint.xsOnly}"
            >{{ $ml.get('about_benefits_title') }}</h3>

            <v-layout row wrap text-xs-center>
              <v-flex xs12 sm4 v-for="(step, index) in how_it_works" :key="index" class="px-5">
                <v-img
                  :aspect-ratio="1"
                  contain
                  class="ma-auto mb-2"
                  position="center bottom"
                  :src="step.icon"
                  :lazy-src="step.icon"
                  width="100px"
                  max-height="100px"
                ></v-img>

                <p class="graphik-light font-15 dark-grey--text mb-4">{{ step.description }}</p>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-container>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: 'ContentAboutBenefits',
  data () {
    return {
      how_it_works: [
        {
          description: this.$ml.get('about_benefits_1_description'),
          icon: require('@/assets/img/icons/blue/umbrella.png')
        },
        {
          description: this.$ml.get('about_benefits_2_description'),
          icon: require('@/assets/img/icons/blue/money.png')
        },
        {
          description: this.$ml.get('about_benefits_3_description'),
          icon: require('@/assets/img/icons/blue/person_add.png')
        }
      ]
    }
  }
}
</script>

<style scoped>
</style>
